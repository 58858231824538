import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchUserGroupsRolesIncidents,
  toggleAllowAdminProfileEdit,
  getAllQuestionAnswer,
  addUserAnswerForGroupQuestion,
} from 'actions/profileActions';
import {
  updateCurrentIncidentById,
  updateCurrentlySelectedGroup,
} from 'actions/diceActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { useNewICSFlag } from 'utils/featureFlags'

export default function AdminUser({ user }) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const [mappedTableData, setMappedTableData] = React.useState([]);

  const isNewICS = useNewICSFlag()

  const reduxGroups =
    useSelector((state) => {
      return state.app.groups;
    }) || [];

  const userGroupsRolesIncidents = useSelector((state) => {
    return state.app.userGroupsRolesIncidents;
  });

  const isFetchUserGroupsRolesIncidentsLoaded = useSelector((state) => {
    return state.app.isFetchUserGroupsRolesIncidentsLoaded;
  });
  const alluserWorkspaces = useSelector((state) => {
    return state.app.alluserWorkspaces;
  });
  useEffect(() => {
    if (
      (!userGroupsRolesIncidents || userGroupsRolesIncidents.length === 0) &&
      !!isFetchUserGroupsRolesIncidentsLoaded
    ) {
      reduxDispatch(fetchUserGroupsRolesIncidents(user.user_guid));
    }
  }, []);

  useEffect(() => {
    reduxDispatch(endLoading());
    if (!!userGroupsRolesIncidents && userGroupsRolesIncidents.length > 0) {
      const tableData = userGroupsRolesIncidents.map((ugri) => {
        const workspace = !!alluserWorkspaces
          ? alluserWorkspaces
              .filter((e) => e.organizationId === ugri.group_guid)
              .map((e) => e.displayName)
              .join(', ')
          : '';
        return {
          ...ugri,
          roles: ugri.group_roles.map((gr) => gr.name).join(', '),
          incidents: ugri.group_incidents.map((gi) => gi.name).join(', '),
          workspace: workspace,
        };
      });
      setMappedTableData(tableData);
    } else {
      setMappedTableData([]);
    }
  }, [userGroupsRolesIncidents, alluserWorkspaces]);

  const allColumns = [
    {
      dataField: 'group_name',
      text: 'Group Memberships',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Group Memberships' },
    },
    {
      dataField: 'roles',
      text: 'Organization Roles',
      sort: true,
      attrs: { title: 'Organization Roles' },
    },
    {
      dataField: 'workspace',
      text: 'Exercise',
      sort: true,
      attrs: { title: 'Exercise' },
    },
    {
      dataField: 'incidents',
      text: 'Incidents',
      sort: true,
      attrs: { title: 'Incidents' },
    },
  ];

  const handleIncidentLinkClick = async (incidentId, groupId) => {
    await reduxDispatch(
      updateCurrentlySelectedGroup(
        reduxGroups.find((g) => g.group_guid === groupId)
      )
    );
    reduxDispatch(updateCurrentIncidentById(incidentId));
    if (isNewICS) {
      navigate(`/iap/${incidentId}/settings`)
    } else {
      navigate('/incident_configuration', { state: { incidentId } });
    }
  };

  const handleGroupLinkClick = (groupId) => {
    reduxDispatch(
      updateCurrentlySelectedGroup(
        reduxGroups.find((g) => g.group_guid === groupId)
      )
    );
    navigate('/groups');
  };

  function handleAdminProfileEditToggle(group_guid) {
    reduxDispatch(toggleAllowAdminProfileEdit(group_guid));
  }

  const onExpandRow = (index) => {
    reduxDispatch(getAllQuestionAnswer(mappedTableData[index].group_guid));
  };

  const updateSelectedValue = (value, question, row) => {
    if (value === '') return;
    const answer = {
      question_id: question.question_id,
      answer: value,
      id: question.answerId ? question.answerId : generateUUID(),
    };
    reduxDispatch(startLoading());
    reduxDispatch(addUserAnswerForGroupQuestion(answer, row.group_guid));
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-4">
              <h6 className="mb-2">Group</h6>
              <p
                className="anchor anchor--white mb-2"
                onClick={() => handleGroupLinkClick(row.group_guid)}
              >
                {row.group_name}
              </p>
            </div>
            <div className="col-md-4 mb-3 mb-md-4">
              <h6 className="mb-2">Organization Roles</h6>
              {!!row.group_roles && !!row.group_roles.length ? (
                <>
                  <ul className="list mb-2">
                    {row.group_roles.map((ra) => {
                      return <li key={'AdminUser-' + ra.name}>{ra.name}</li>;
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <div className="col-md-4 mb-3 mb-md-4">
              <h6 className="mb-2">Incidents</h6>
              {!!row.group_incidents && !!row.group_incidents.length ? (
                <>
                  <ul className="list mb-2">
                    {row.group_incidents.map((i) => {
                      return (
                        <li key={i.id}>
                          <p
                            className="anchor anchor--white m-0"
                            onClick={() =>
                              handleIncidentLinkClick(i.id, row.group_guid)
                            }
                          >
                            {i.name}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <hr className="dashed m-0" />
            <div className="col-md-12 mt-4">
              <StylishNewCheckbox
                checked={row.allow_admin_profile_edits}
                label={`The group admin can edit my profile`}
                onClick={() => handleAdminProfileEditToggle(row.group_guid)}
              />
            </div>
            {row.group_question && row.group_question.length > 0 && (
              <>
                <hr className="dashed mt-4 mb-0" />
                <div className="col-md-12 mt-4">
                  <h6 className="mb-2">Additional Group Member Data</h6>
                  {row.group_question.map((question, idx) => {
                    return (
                      <>
                        <div className={`${idx !== 0 ? 'mt-2' : ''}`}>
                          <label className="form-label">
                            {question.question}
                          </label>
                          <StylishNewSelect
                            options={question.options.map((i) => {
                              return { value: i, label: i };
                            })}
                            value={{
                              value: question.answer,
                              label: question.answer,
                            }}
                            onChange={(e) =>
                              updateSelectedValue(e.value, question, row)
                            }
                            isDisabled={false}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            menuPlacement="top"
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      onExpandRow(rowIndex);
    },
  };

  return (
    <>
      {(!!userGroupsRolesIncidents &&
        !!isFetchUserGroupsRolesIncidentsLoaded && (
          <>
            <StylishNewTable
              keyField={'group_guid'}
              columns={allColumns}
              rows={mappedTableData}
              expandRow={expandRow}
            />
          </>
        )) || (
        <p className="weight-500 m-0">Loading User Groups and Incidents...</p>
      )}
    </>
  );
}
