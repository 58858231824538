import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dayjs from 'dayjs';
import { productionEnv } from 'constants/debug';
import 'react-toastify/dist/ReactToastify.css';

import { changeFeatureFlags } from 'actions/featureFlagActions';

import { fetchCoreComponents, updateCurrentAOR } from 'actions/ai2fpActions';

import apiconfig from 'constants/apiConfig';

import {
  createOrGetUser,
  fetchGroups,
  getEnvironmentVariables,
  updateCurrentlySelected,
} from './actions/profileActions';

import {
  updateCurrentIncident,
  updateCurrentlySelectedGroup,
} from './actions/diceActions';

import { validateRBACPermissionForAction } from './actions/validationActions';

import { clearNotificationStatus } from 'actions/notificationActions';

import {
  handleEndpointUpdate,
  setIsSocketOpen,
  setSocketAssetRisks,
} from './actions/websocketActions';

import { setDchatStream } from 'slices/dchatSlice';

import axios from 'axios';

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { tokenRequest } from 'components/Auth/MSALAuthObjects';

import { trackUserLogin } from 'actions/trackingActions';
import Loader from 'components/Loader/loader';
import { useAuth0 } from '@auth0/auth0-react';
import Network from 'PREPAREsrc/service/Network';
import ErrorBoundary from 'components/Error/ErrorBoundary';

import { fetchUserSubscriptionInfo } from 'actions/stripeActions';
import { updateTeamsTenants } from 'actions/teamsActions';
import PratusRoutes from './routes/PratusRoutes';
import RouteError from './components/Error/RouteError';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Remove ResizeObserver - loop limit exceeded
export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

function App() {
  const { getAccessTokenSilently } = useAuth0();

  // location
  const location = useLocation();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const reduxGroups = useSelector((state) => {
    return state.app.groups;
  });

  const areGroupsStable = useSelector((state) => {
    return state.app.areGroupsLoaded;
  });

  const groups = useSelector((state) => {
    return state.app.groups;
  });

  const [isDev, setIsDev] = useState(false);
  const userAgent = useRef(navigator.userAgent);

  useEffect(() => {
    if (groups && groups.length) {
      const devFound =
        !!groups.find((group) =>
          group.group_name.includes('DisasterTechDev')
        ) ||
        user.email_address.includes('@qn1k.onmicrosoft.com') ||
        user.email_address.includes('@13bl1.onmicrosoft.com');
      setIsDev(devFound);
    }
  }, [groups]);

  const auth = useSelector((state) => {
    return state.auth;
  });

  const user = useSelector((state) => {
    return state.app.user;
  });

  const reduxIncidents = useSelector((state) => {
    return state.app.incidents;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const { userName } = useSelector((state) => {
    return state.auth.account;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isValidateRBACPermissionForActionLoaded = useSelector((state) => {
    return state.app.isValidateRBACPermissionForActionLoaded;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentAOR = useSelector((state) => {
    return state.app.currentAOR;
  });

  const reduxCoreComponents = useSelector((state) => {
    return state.app.coreComponents;
  });

  const isFetchCoreComponentsLoaded = useSelector((state) => {
    return state.app.isFetchCoreComponentsLoaded;
  });

  const isEnvironmentVariableLoaded = useSelector((state) => {
    return state.app.isEnvironmentVariableLoaded;
  });

  const [
    hasNewUserNavigatedAwayFromProfile,
    setHasNewUserNavigatedAwayFromProfile,
  ] = useState(false);

  if (!sessionStorage['accessToken']) {
    sessionStorage['accessToken'] = auth.accessToken;
    sessionStorage['accessTokenExpiresOn'] = auth.expiresOn;
    sessionStorage['isUsingTeams'] = auth.teamsInitialized;
  }

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        // Default settings
        config.credentials = 'same-origin';
        config.headers.Accept = 'application/json';
        const fromDataUrl = ['uploadFile', 'bulkUserImport'];

        if (
          config.data instanceof FormData ||
          fromDataUrl.includes(config.url.split('?')[0].split('/').pop())
        ) {
          config.headers['Content-Type'] = 'multipart/form-data';
        } else {
          config.headers['Content-Type'] = 'application/json';
        }

        // Auth refresh
        let now = dayjs();
        let expiresOn = dayjs(sessionStorage['accessTokenExpiresOn']);

        // Compare the current time to the token expiration datetime
        // If the result is positive, then the token is still valid and the existing accessToken can be used
        // If the result is negative, then a new token needs to be silently fetched
        // An additional 1 minute is added for edge cases where a request is made just before the auth token expires
        if (
          expiresOn.diff(now.add(1, 'minute')) > 0 ||
          sessionStorage['isUsingTeams'] === 'true'
        ) {
          config.headers['x-teamsapp'] =
            sessionStorage['isUsingTeams'] === 'true' || auth.type === 'MSAL';
          Network.http.defaults.headers.common['x-teamsapp'] =
            sessionStorage['isUsingTeams'] === 'true';
          if (auth.type === 'AUTH0') {
            const auth0Token = await getAccessTokenSilently();
            config.headers.Authorization = `Bearer ${auth0Token}`;
            Network.http.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${auth0Token}`;
            return Promise.resolve(config);
          } else {
            config.headers.Authorization = `Bearer ${sessionStorage['accessToken']}`;
            Network.http.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${sessionStorage['accessToken']}`;

            return Promise.resolve(config);
          }
        } else {
          let refreshTokenResponse;
          // Fetch the token.  If there is an error, then the user is notified that their session has expired and the page reloads.
          if (auth.type === 'MSAL') {
            refreshTokenResponse = await auth.msalInstance.acquireTokenSilent({
              ...tokenRequest,
              account: auth.account,
            });
          } else if (auth.type === 'AUTH0') {
            // need some way to get the token...
            const auth0Token = await getAccessTokenSilently();
            refreshTokenResponse = {
              accessToken: auth0Token,
              expiresOn: now.add(1, 'hour'),
            };
          }

          sessionStorage['accessToken'] = refreshTokenResponse.accessToken;
          sessionStorage['accessTokenExpiresOn'] =
            refreshTokenResponse.expiresOn;
          config.headers.Authorization = `Bearer ${refreshTokenResponse.accessToken}`;
          Network.http.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${refreshTokenResponse.accessToken}`;
          return Promise.resolve(config);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [auth.type]);

  useEffect(() => {
    const comsWebSocket = productionEnv
      ? window.env.WEBSOCKET
      : process.env.REACT_APP_WEBSOCKET;

    let client;
    let retries = 0;
    const maxRetries = 4;
    const retryDelay = 3000; // 3 seconds

    const connectWebSocket = () => {
      try {
        client = new WebSocket(comsWebSocket);
        console.log("Websocket connected to client",connectWebSocket)
      } catch (error) {
        console.error('Error creating WebSocket client', error);
        return;
      }

      client.onopen = () => {
        reduxDispatch(setIsSocketOpen(true));
        retries = 0; // Reset retries on successful connection
      };

      client.onmessage = (message) => {
        if (message && message.data) {
          const payload = JSON.parse(message.data);

          if (payload.assetRisks) {
            reduxDispatch(setSocketAssetRisks(payload.assetRisks));
          }

          if (payload.endpoint) {
            reduxDispatch(handleEndpointUpdate(payload));
          }

          if (payload.streamtext) {
            reduxDispatch(setDchatStream(payload.streamtext));
          }
        }
      };

      client.onerror = () => {
        console.error('WebSocket encountered an error.');
      };

      client.onclose = () => {
        if (retries < maxRetries) {
          setTimeout(() => {
            retries += 1;
            console.warn(`WebSocket connection lost. Retrying... (${retries}/${maxRetries})`);
            connectWebSocket(); // Retry the connection
          }, retryDelay);
        } else {
          console.error('Failed to reconnect after 4 attempts.');
        }
      };
    };

    connectWebSocket();

    const stayAliveInterval = setInterval(() => {
      if (client && client.readyState === WebSocket.OPEN) {
        client.send('stay alive');
      }
    }, 40000);

    return () => {
      clearInterval(stayAliveInterval);
      if (client) {
        client.close();
      }
    };
  }, []);


  // ResizeObserver - loop limit exceeded
  useBeforeRender(() => {
    window.addEventListener('error', (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr)
          resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Tab') {
        document.body.classList.add('user-is-tabbing');
      }
    }

    function handleMouseDown() {
      document.body.classList.remove('user-is-tabbing');
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (
      userAgent.current.includes('Safari') &&
      !!auth &&
      !auth.teamsInitialized
    ) {
      const split = userAgent.current.split(' Safari');
      const innerSplit = split[0].split('/');
      const version = innerSplit[innerSplit.length - 1];
      if (parseFloat(version) < 15.4) {
        window.alert(
          'Your browser is out of date, please update or use an alternate browser.'
        );
      }
    }
  }, [userAgent, auth]);

  useEffect(() => {
    if (auth.teamsInitialized) {
      const token = auth.accessToken;
      const payload = JSON.parse(atob(token.split('.')[1]));
      const tenantId = payload.tid;
      if (tenantId) {
        reduxDispatch(updateTeamsTenants(tenantId));
      }
    }
  }, [auth]);

  useEffect(() => {
    reduxDispatch(trackUserLogin());
    reduxDispatch(getEnvironmentVariables());

    reduxDispatch(
      createOrGetUser({
        userName,
      })
    );
  }, [userName]);

  useEffect(() => {
    if (isEnvironmentVariableLoaded) {
      const defaultFeatureFlagsFromEnv = window.env.DEFAULT_FEATURE_FLAGS;

      if (!!defaultFeatureFlagsFromEnv) {
        const s = new Set([
          ...reduxFeatureFlags,
          ...defaultFeatureFlagsFromEnv.trim().split(','),
        ]);

        reduxDispatch(changeFeatureFlags(Array.from(s)));
      }
    }
  }, [isEnvironmentVariableLoaded]);

  useEffect(() => {
    const { user_guid } = user || {};
    if (user && user_guid) {
      // reduxDispatch(fetchIncidents());
      if (
        areGroupsStable &&
        !groups &&
        JSON.stringify(reduxCurrentlySelectedGroup) !== '{}'
      ) {
        reduxDispatch(fetchGroups());
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      (!!reduxGroups &&
        !!areGroupsStable &&
        !!isValidateRBACPermissionForActionLoaded &&
        !reduxCurrentlySelectedGroup) ||
      (!!reduxCurrentlySelectedGroup &&
        !!reduxCurrentlySelectedGroup.group_guid &&
        !reduxGroups.find(
          (g) => g.group_guid === reduxCurrentlySelectedGroup.group_guid
        ))
    ) {
      // If there are groups, but the current selection isn't one of them, we need to change the selection to be the first group in the list (for simplicity)
      if (
        !!reduxGroups.length &&
        (!reduxCurrentlySelectedGroup ||
          !reduxCurrentlySelectedGroup.group_guid ||
          !reduxGroups.find(
            (g) => g.group_guid === reduxCurrentlySelectedGroup.group_guid
          ))
      ) {
        reduxDispatch(updateCurrentlySelectedGroup(reduxGroups[0]));
      }
    }
  }, [reduxCurrentlySelectedGroup, reduxGroups]);

  useEffect(() => {
    if (
      !!isValidateRBACPermissionForActionLoaded &&
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid &&
      !!reduxGroups &&
      !!reduxGroups.find(
        (g) => g.group_guid === reduxCurrentlySelectedGroup.group_guid
      )
    ) {
      reduxDispatch(
        validateRBACPermissionForAction(reduxCurrentlySelectedGroup.group_guid)
      );

      if (reduxCurrentIncident?.group_guid === reduxCurrentlySelectedGroup?.group_guid) {
        // do nothing
      } else if (reduxIncidents) {
        const newCurrentGroupIncident = reduxIncidents.filter(
          (i) => i.group_guid === reduxCurrentlySelectedGroup.group_guid
        )[0];
        reduxDispatch(updateCurrentIncident(newCurrentGroupIncident));
      }
    }
  }, [reduxCurrentlySelectedGroup,reduxIncidents]);

  useEffect(() => {
    if (
      !!isFetchCoreComponentsLoaded &&
      !!isValidateRBACPermissionForActionLoaded &&
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid &&
      !!reduxGroups &&
      !!reduxGroups.find(
        (g) => g.group_guid === reduxCurrentlySelectedGroup.group_guid
      )
    ) {
      reduxDispatch(
        fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup, reduxFeatureFlags]);

  const isUpdateCurrentlySelectedLoaded = useSelector((state) => {
    return state.app.isUpdateCurrentlySelectedLoaded;
  });

  useEffect(() => {
    if (!!user && !!user.currently_selected && !!location) {
      if (
        !!isUpdateCurrentlySelectedLoaded &&
        (!user.currently_selected.route ||
          (!!user.currently_selected.route &&
            user.currently_selected.route !== location.pathname))
      ) {
        reduxDispatch(updateCurrentlySelected({ route: location.pathname }));
      }
    }
    if (!location.pathname.includes('notifications')) {
      reduxDispatch(clearNotificationStatus());
    }
  }, [location]);

  useEffect(() => {
    if (!window.location.href.includes('stripeCreateSessionResult')) {
      if (
        !!user &&
        user.profile_settings.is_new_user === 'true' &&
        !hasNewUserNavigatedAwayFromProfile
      ) {
        setHasNewUserNavigatedAwayFromProfile(true);
      } else if (
        !!user &&
        location.pathname === '/' &&
        !!user.currently_selected &&
        !!user.currently_selected.route
      ) {
        navigate(user.currently_selected.route);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!!user && !!reduxFeatureFlags.includes('STRIPE')) {
      reduxDispatch(fetchUserSubscriptionInfo());
    }
  }, [user, reduxFeatureFlags]);

  useEffect(() => {
    if (!!user && !!user.currently_selected && !!reduxCoreComponents) {
      if (!!reduxCoreComponents.CCs['Areas of Responsibility'].length) {
        const foundAOR = reduxCoreComponents.CCs[
          'Areas of Responsibility'
        ].find((aor) => aor.id === user.currently_selected.aor_id);
        if (
          !foundAOR &&
          !!reduxCoreComponents.CCs['Areas of Responsibility'].length
        ) {
          reduxDispatch(
            updateCurrentAOR(
              reduxCoreComponents.CCs['Areas of Responsibility'][0]
            )
          );
        } else if (!reduxCurrentAOR || reduxCurrentAOR.id !== foundAOR.id) {
          reduxDispatch(updateCurrentAOR(foundAOR));
        }
      }
    }
  }, [user, reduxCoreComponents]);

  if (
    typeof user === 'undefined' ||
    !reduxIncidents ||
    !reduxGroups ||
    (!isValidateRBACPermissionForActionLoaded &&
      !reduxValidateRBACPermissionForActionResult)
  ) {
    return <Loader showLoading={true} />;
  }

  return (
    <Suspense>
      <ErrorBoundary location={location}>
        {isEnvironmentVariableLoaded ? (
          <PratusRoutes isDev={isDev} />
        ) : null}
      </ErrorBoundary>
    </Suspense>
  );
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
    errorElement: <RouteError />,
  },
]);

const queryClient = new QueryClient();

// We wrap App with the router here so we can use React Router tools like history/location at the top level component
const AppWrap = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/*<ReactQueryDevtools /> /!** NOTE: excluded in production builds. TODO: hide this more. *!/*/}
    </QueryClientProvider>
  </ErrorBoundary>
);
AppWrap.displayName = 'AppWrap';
export default AppWrap;
