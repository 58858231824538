import IncidentConfigureTab from './IncidentConfigureTab';
import React from 'react';
import { useNewICSFlag } from '../../utils/featureFlags';
import { useNavigate, useParams } from 'react-router-dom';
import { useActualIncidentId } from '../IAP/hooks/commonHooks';


const IncidentConfigurationRedirect: React.FC = () => {
  const newICS = useNewICSFlag();
  const navigate = useNavigate();
  const {incident} = useParams<{incident: UUID}>()
  const actualIncidentId = useActualIncidentId();

  if (newICS) {
    navigate(`/iap/${incident ?? actualIncidentId}/settings`);
  }

  return (<IncidentConfigureTab />);
}
export default IncidentConfigurationRedirect;